import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/earns/inventory-items', { params })
    return response
  },
  async getAll(params){
    let response = await api.get('/admin/earns/inventory-items/all', { params })
    return response
  }
//   async create(payload) {
//     const response = await api.post('/admin/earns/balance', payload)
//     return response.data.data.new_document || null
//   },
//   async update(payload) {
//     let response = await api.put('/admin/earns/balance', payload)
//     return response
//   },
//   async delete(payload) {
//     const response = await api.delete('/admin/earns/balance', {
//       data: payload,
//     })
//     return response
//   },
}
