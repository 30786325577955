<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group label="User ID" label-for="user_id">
          <b-form-input
            v-model="dataForm.user_id"
            id="user_id"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Token ID" label-for="token-id">
          <b-form-input
            v-model="dataForm.nft_item.id"
            id="token-id"
            type="number"
            class="mr-1"
          />
        </b-form-group>
      </b-col>
      
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Quality" label-for="quality">
          <b-form-select
            v-model="dataForm.nft_item.quality"
            id="quality"
            :options="quality_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Type" label-for="type">
          <b-form-select
            v-model="dataForm.nft_item.type"
            id="type"
            :options="type_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Generation" label-for="generation">
          <b-form-select
            v-model="dataForm.nft_item.box_type"
            id="generation"
            :options="generation_options"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="mb-2 d-flex justify-content-end">
      <b-button @click="search" class="mt-0 mt-md-2 mr-1" variant="primary">
        Filter
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataForm: {
        nft_item: {
          type: null,
          quality: null,
          box_type: null,
        }
      },
      balance_min: null,
      balance_max: null,
      type_options: [
        {text: "All", value: null},
        {text: "Box", value: 1},
        {text: "GOGA", value: 2}
      ],
      quality_options: [
        {text: "All", value: null},
        {text: "Normal", value: 1},
        {text: "Good", value: 2},
        {text: "Rare", value: 3},
        {text: "Epic", value: 4},
        {text: "Legendary", value: 4},
      ],
      generation_options: [
        {text: "All", value: null},
        {text: "Genesis", value: 1},
      ]
    };
  },
  methods: {
    search() {
      this.$emit("getList", this.dataForm);
    },
  },
};
</script>
