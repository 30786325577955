<template>
    <b-modal
      v-if="item"
      centered
      title="Detail"
      v-model="isModalShow"
      id="modal-xl"
      size="xl"
      hide-footer
    >
        <div class="pr-2">
            <div>
                <b-button @click="isBasicShow = true; isAttributeShow = false" :variant="isBasicShow ? `info` : ``">Basic</b-button>
                <b-button @click="isAttributeShow = true; isBasicShow = false" :variant="isAttributeShow ? `info` : ``">Attribute</b-button>
            </div>
            <div v-if="isBasicShow" class="mt-2">
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span class="fw-bold">Name</span>
                    </b-col>
                    <b-col>
                        <b-input v-model="item.nft_item.name" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span class="fw-bold">Token ID</span>
                    </b-col>
                    <b-col>
                        <b-input v-model="item.id" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span class="fw-bold">Creator address</span>
                    </b-col>
                    <b-col>
                        <b-input v-model="item.nft_item.creator_addr" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span class="fw-bold">Image Uri</span>
                    </b-col>
                    <b-col>
                        <b-input v-model="item.nft_item.img_uri" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span class="fw-bold">Collection</span>
                    </b-col>
                    <b-col>
                        <b-input v-model="item.nft_item.collection" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span class="fw-bold">Token address</span>
                    </b-col>
                    <b-col>
                        <b-input v-model="item.nft_item.token_addr" disabled />
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col cols="6">
                        <b-row class="align-items-center">
                            <b-col cols="4">
                                <span class="fw-bold">Type</span>
                            </b-col>
                            <b-col>
                                <b-input :value="convertType(item.nft_item.type)" disabled />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6">
                        <b-row class="align-items-center">
                            <b-col cols="4">
                                <span class="fw-bold">Generation</span>
                            </b-col>
                            <b-col>
                                <b-input :value="convertBoxType(item.nft_item.box_type)" disabled />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col cols="6">
                        <b-row class="align-items-center">
                            <b-col cols="4">
                                <span class="fw-bold">Minting count total</span>
                            </b-col>
                            <b-col>
                                <b-input v-model="item.nft_item.minting_count_total" disabled />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6">
                        <b-row class="align-items-center">
                            <b-col cols="4">
                                <span class="fw-bold">Minting count remain</span>
                            </b-col>
                            <b-col>
                                <b-input v-model="item.nft_item.minting_count_remain" disabled />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col cols="6">
                        <b-row class="align-items-center">
                            <b-col cols="4">
                                <span class="fw-bold">Status</span>
                            </b-col>
                            <b-col>
                                <b-input :value="convertStatus(item.nft_item.status)" disabled />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6">
                        <b-row class="align-items-center">
                            <b-col cols="4">
                                <span class="fw-bold">Lock releases time</span>
                            </b-col>
                            <b-col>
                                <b-input :value="convertTime(item.nft_item.lock_release_time)" disabled />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <div v-if="isAttributeShow" class="mt-2">
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span>Level</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.level" disabled />
                    </b-col>
                    <b-col cols="2">
                        <span>Battery</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.batery" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span>Quality</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.quality" disabled />
                    </b-col>
                    <b-col cols="2">
                        <span>Skill point</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.attr_point" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span>Intelligence Added Points</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.intel" disabled />
                    </b-col>
                    <b-col cols="2">
                        <span>Intelligence base</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.intel_base" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span>Agility Added Points</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.agi" disabled />
                    </b-col>
                    <b-col cols="2">
                        <span>Agility base</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.agi_base" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span>Luck Added Points</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.luck" disabled />
                    </b-col>
                    <b-col cols="2">
                        <span>Luck base</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.luck_base" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span>Power Added Points</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.power" disabled />
                    </b-col>
                    <b-col cols="2">
                        <span>Power base</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.power_base" disabled />
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                    <b-col cols="2">
                        <span>Cycle count total</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.cycle_count_total" disabled />
                    </b-col>
                    <b-col cols="2">
                        <span>Cycle count</span>
                    </b-col>
                    <b-col cols="4">
                        <b-input v-model="item.nft_item.cycle_count" disabled />
                    </b-col>
                </b-row>
            </div>
        </div>
    </b-modal>
</template>
<script>
    export default {
        props:{
            item: {type: Object, default: null}
        },
        data(){
            return {
                isModalShow: false,
                isBasicShow: true,
                isAttributeShow: false
            }
        },
        methods:{
            openModal(){
                this.isModalShow = true;
            },
            convertQualityToName(quality){
                switch(quality){
                    case 1:
                        return "NORMAL"
                    case 2:
                        return "GOOD"
                    case 3:
                        return "RARE"
                    case 4:
                        return "EPIC"        
                    case 5:
                        return "LEGENDARY"
                    default:
                        return null
                }
            },
            convertType(value){
                switch (value){
                    case 1: return "Box"
                    case 2: return "GOGA"
                    default: return "Unknown"
                }
            },
            convertBoxType(value){
                switch (value){
                    case 1: return "Genesis"
                    default: return "Unknown"
                }
            },
            convertStatus(value){
                switch (value){
                    case 1: return "ACTIVE"
                    case 2: return "LOCK LEVELUP"
                    case 3: return "LOCK_TRANSFER"
                    case 4: return "TRANSFER_RESERVE"
                    default: return "Unknown"
                }
            },
            convertTime(value){
                if (value){
                    let date = new Date(value)
                    return date.toLocaleString('vi-VN')
                }
                return 0
            }
        }
}
</script>
<style scoped>
    .fw-bold{
        font-weight: bold;
    }
</style>