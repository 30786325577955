import { render, staticRenderFns } from "./NftTable.vue?vue&type=template&id=31ef3706&scoped=true&"
import script from "./NftTable.vue?vue&type=script&lang=js&"
export * from "./NftTable.vue?vue&type=script&lang=js&"
import style0 from "./NftTable.vue?vue&type=style&index=0&id=31ef3706&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ef3706",
  null
  
)

export default component.exports