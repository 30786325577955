<template>
    <div>
        <b-table :items="items" :fields="fields">
            <template #cell(name)="data">
                <span class="click-able" @click="current_item = data.item; openModal()"> {{ data.item.nft_item.name }} </span>
            </template>
            <template #cell(img_uri)="data">
                <img :src="data.item.nft_item.img_uri" alt="season img" width="160">
            </template>
        </b-table>
        <nft-model :item="current_item" ref="nftModal" />
    </div>
</template>
<script>
import NftModel from "./NftModel.vue"
export default {
    components:{
        NftModel
    },
    props:{
        items: {type: Array, default: null},
        is_loading: {type: Boolean, default: true}
    },
    data(){
        return {
            fields: [
                {
                    key: "user_id",
                    label: "User id",
                    class: 'text-center'
                },
                {
                    key: "name",
                    label: "Name NFT",
                    class: 'text-center'
                },
                {
                    key: "img_uri",
                    label: "Image Uri",
                    class: 'text-center',
                    image: true
                },
                {
                    key: "nft_item.id",
                    label: "Token ID",
                    class: 'text-center'
                },
                {
                    key: "nft_item.type",
                    label: "Type",
                    class: 'text-center',
                    formatter: value => {
                        return this.convertType(value)
                    },
                },
                {
                    key: "nft_item.quality",
                    label: "Quality",
                    class: 'text-center',
                    formatter: value => {
                        return this.convertQualityToName(value)
                    },
                },
            ],
            current_item: null,
        }
    },
    methods: {
        convertQualityToName(quality){
            switch(quality){
                case 1:
                    return "NORMAL"
                case 2:
                    return "GOOD"
                case 3:
                    return "RARE"
                case 4:
                    return "EPIC"        
                case 5:
                    return "LEGENDARY"
                default:
                    return null
            }
        },
        openModal(){
            this.$refs.nftModal.openModal();
        },
        convertType(value){
            switch (value){
                case 1: return "Box"
                case 2: return "GOGA"
                default: return "Unknown"
            }
        },
        convertBoxType(value){
            switch (value){
                case 1: return "Genesis"
                default: return "Unknown"
            }
        }
    }
}
</script>
<style scoped>
    .click-able:hover {
        cursor: pointer;
        color: #1198F6
    }
</style>